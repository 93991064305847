import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"

const CloduData = () => {
  const data = useStaticQuery(graphql`
    query Images_CloduData {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soluciones Cloud Data para Empresas | Qualoom</title>
        <meta name="title" content="Soluciones Cloud Data para Empresas | Qualoom"/>
        <meta name="description" content="Cloud Data para Empresas proporcionando soluciones en la Nube. Agilidad e innovación para crear una nube de datos propia. ¡Consúltanos sin compromiso!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/cloud-data/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/cloud-data/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/cloud-data/"/>
        <meta property="og:title" content="Soluciones Cloud Data para Empresas | Qualoom"/>
        <meta property="og:description" content="Cloud Data para Empresas proporcionando soluciones en la Nube. Agilidad e innovación para crear una nube de datos propia. ¡Consúltanos sin compromiso!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/cloud-data/"/>
        <meta property="twitter:title" content="Soluciones Cloud Data para Empresas | Qualoom"/>
        <meta property="twitter:description" content="Cloud Data para Empresas proporcionando soluciones en la Nube. Agilidad e innovación para crear una nube de datos propia. ¡Consúltanos sin compromiso!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/cloud-data/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Cloud Data", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/cloud-data/", "name": "Cloud Data"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Cloud Data</h1>
              <p>En Qualoom, ofrecemos servicios de valor añadido en Cloud Data para empresas. Nuestro enfoque se centra en proporcionar servicios profesionales que permitan utilizar las soluciones y aplicaciones de los principales proveedores cloud (Microsoft, AWS y Google) para la ingesta, almacenamiento y explotación de los datos de las empresas. Con nuestra ayuda podrás aprovechar al máximo el potencial de tus datos e impulsar la toma de decisiones basadas en información sólida paraa consolidar una clara ventaja competitiva en el mercado.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Calidad y datos fiables</h2><hr></hr>
          <p>En Qualoom, entendemos la importancia de la calidad y fiabilidad de los datos en la nube. Nuestros servicios profesionales están diseñadas para garantizar la integridad, consistencia y disponibilidad de tus datos. Conocemos las Utilizamos tecnologías y las mejores prácticas en gestión de datos para asegurar que sean confiables y precisos.</p><br/>
          <div className="section-main">
            <div className="section-img cloud-management"></div>
            <div className="section-text">
              <h3>Cloud data management</h3>
              <p>La gestión de datos en la nube es clave para optimizar su uso y aprovechar su potencial. En Qualoom ofrecemos soluciones integrales, desde la recopilación y el almacenamiento hasta el procesamiento y el análisis. Nuestro enfoque se basa en utilizar tecnologías de vanguardia y herramientas especializadas para ofrecerte una gestión eficiente y segura en la nube.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Resuelve los problemas de datos rápidamente</h2><hr></hr>
          <p>Entendemos que los problemas relacionados con los datos pueden obstaculizar el crecimiento de las empresas. Nuestros servicios te ayudarán a resolver rápidamente los desafíos relacionados con la calidad, integridad y accesibilidad de tus datos en la nube. Utilizamos enfoques innovadores y conocemos las tecnologías utilizadas para garantizar que tus datos estén siempre disponibles y sean confiables.</p><br/>
          <div className="section-main">
            <div className="section-text">
              <h3>Sencillez, automatización y eficacia</h3>
              <p>Nuestro objetivo es simplificar tus procesos, automatizar tareas y mejorar la eficiencia operativa. Con Qualoom, podrás aprovechar al máximo tus datos en la nube de manera ágil y rentable, sin complicaciones innecesarias.</p>
            </div>
            <div className="section-img problems"></div>
          </div>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Qualoom, Partner de los principales Cloud<br/>(AWS, Azure y Google Cloud Platform)</h2><hr></hr>
          <p>Como partner certificado de los principales proveedores de servicios en la nube (AWS, Azure y Google Cloud Platform), podemos ofrecerte soluciones respaldadas por la infraestructura y la experiencia de los líderes del mercado. Trabajaremos contigo para seleccionar la plataforma más adecuada para tus necesidades y brindarte soluciones personalizadas y escalables.</p><br/>
          <h3>Soluciones cloud para empresas</h3>
          <p>Nos especializamos en ofrecer soluciones en la nube adaptadas a las necesidades de las empresas.  Sea cual sea el tamaño o sector de tu empresa, en Qualoom tenemos la experiencia y el conocimiento que necesitas para alcanzar tus objetivos empresariales.</p>
          <a className="mail-button" href="mailto:contacto@qualoom.es?subject=Qualoom · Servicios Cloud · Soluciones Cloud Data">Quiero saber más sobre soluciones Cloud Data</a>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CloduData